@use "../mixins/mediaQueries" as *;
@use "../base/colors" as c;

.nav {
  display: flex;
  align-items: center;
  padding: 0.25em 1em;
  position: relative;
  z-index: 10;
  min-height: 4em;

  &__logo {
    position: absolute;
    height: 64px;
    top: -1rem;
  }

  &__button {
    //padding: 0.25em;
    border: none;
    background: transparent;
    font-size: 1.5rem;
  }

  &__list {
    text-align: center;
    position: absolute;
    top: 75px;
    width: 90%;
    max-width: 700px;
    left: 50%;
    padding: 2em;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) inset;
    background: c.$yellow-dark;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 300ms ease-in, opacity 200ms ease-in;
    list-style: none;
    border-radius: 3px;
  }

  &__list-item {
    margin-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid rgba(black, 0.2);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    &--language {
      position: absolute;
      margin-bottom: 0;
      padding-bottom: 0;
      top: 0.5em;
      right: 0.5em;
      border-bottom: none;
      font-size: 1.25rem;
      a {
        font-size: 1.25rem;
      }
    }
  }

  &__link {
    display: inline-block;

    &:not(:last-of-type) {
      padding-bottom: 1em;
      margin-bottom: 1em;
    }
  }

  &__button {
    cursor: pointer;
  }

  &__languages {
    display: flex;
    gap: 1em;
  }
}

@include for-tablet-landscape-up {
  .nav {
    justify-content: flex-end;
    padding-top: 0;
    padding-bottom: 0;

    &__list {
      position: static;
      transform: translate(0);
      opacity: 1;
      display: flex;
      flex-direction: row;
      width: auto;
      max-width: auto;
      padding: 0;
      background: transparent;
      box-shadow: none;
      margin-left: 8em;
    }

    &__list-item {
      margin-top: 0;
      padding-bottom: 0;
      border-bottom: none;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &__item {
      &:not(:last-of-type) {
        margin: 0;
        border-bottom: none;
      }
    }

    &__link {
      padding: 1.5em;
      box-shadow: inset 3px 1px 9px -9px #000;

      &:hover,
      &:focus {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
    }

    &__logo {
      top: -25px;
      left: 40px;
      height: 80px;
    }

    &__button {
      display: none;
    }
  }
}
