@use "../mixins/mediaQueries" as *;
@use "../base/colors" as c;

.footer {
  background: c.$dark-grey;
  position: relative;
  padding: 2em 2em 3em;

  strong {
    font-weight: 700;
    font-family: "KLBulletinSans-Bold", arial, sans-serif;
    font-weight: 400;
  }

  &__list {
    color: white;
    list-style: none;
    margin-bottom: 2em;
  }

  &__list--address {
    display: flex;
    flex-direction: column;
  }

  &__list--social {
    margin: 0;
  }

  &__list-item,
  &__link {
    color: white;
  }

  &__list-item {
    margin-bottom: 0.25em;
  }

  &__link {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__social {
    position: absolute;
    right: 2rem;
    bottom: 2.5em;
    font-size: 2rem;
  }

  &__social-icon {
    color: white;
    text-decoration: none;
    &:hover,
    &:focus {
      color: c.$yellow;
    }
  }
}

@include for-desktop-up {
  .footer {
    padding: 2.5em 6em 3em;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &__container {
      display: flex;
      align-items: flex-end;
    }

    &__list {
      display: flex;
      margin-bottom: 0;

      &:not(:last-of-type) {
        margin: 0;
      }

      &--address {
        &:not(:last-of-type) {
          margin-right: 4em;
        }
      }
    }

    &__list-item {
      &:not(:last-of-type) {
        margin-right: 1em;
      }
    }

    &__social {
      position: absolute;
      font-size: 2rem;
      right: 2.5em;
      top: 1em;
      bottom: auto;
    }
  }
}
