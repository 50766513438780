@use "../mixins/mediaQueries" as *;
@use "../base/colors" as c;

.moments {
  background: c.$yellow;
  padding: 2em;

  &__title {
    margin-bottom: 0.25em;
  }

  &__subtitle {
    margin-bottom: 2em;
  }
}

@include for-tablet-landscape-up {
  .moments {
    padding: 3em 2em;

    &__container {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
  }
}

@include for-desktop-up {
  .moments {
    &__container {
      -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      -ms-grid-rows: 180px 180px 180px 180px 180px 180px 180px;
      grid-template-rows: repeat(7, 180px);
    }
  }
}
