.imprint {
  background: white;
  padding-top: 2em;

  h1,
  h2,
  h3,
  p {
    margin: 1em 1em 0.5em;
  }

  p,
  li {
    line-height: 1.4;
  }

  p strong {
    display: inline-block;
    margin: 1em 0 0.5em;
  }

  a {
    color: black;
  }

  ul {
    margin-left: 1em;
  }

  li {
    margin-left: 1em;
  }
}
