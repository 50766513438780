@use "../mixins/mediaQueries" as *;
@use "../base/colors" as c;

.header {
  background: c.$yellow;

  &__language-picker {
    text-align: right;
    padding: 0.5em 1em;
    background: c.$dark-grey;
  }
}

@include for-tablet-landscape-up {
  .header {
    &__language-picker {
      display: block;
      min-height: 2em;
    }
  }
}
