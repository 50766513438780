@use "../mixins/mediaQueries" as *;

@include for-tablet-portrait-up {
  .container {
    margin: 0 auto;
    max-width: 1300px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

    &--no-shadow {
      box-shadow: none;
    }
  }
}
