// Buttons
$button-color_bg: #333333;
$button-color_hover: darken($button-color_bg, 5%);
$button-color_font: #f1f1f1;
$button-settings-color_bg: #f1f1f1ee;
$button-settings-color_font: darken(#aaa, 10%);
$button-activateAll-color_bg: #f1f1f1;
$button-activateAll-color_font: #333333;
// Slider
$slider-color_bg: #333333;
$slider-color_circle: #fff;

// different text-colors
$accent-color: #333333;

// Main Font-Color
$color_text: #000;
$color_purpose: lighten($color_text, 30%);

$default_text_size: 18px;

// Background-color
$background-color: #ffee00;

.as-oil.light {
  color: $color_text;

  // Zustimmen und fortfahren untereinander und Button einrücken
  .as-oil-cpc__content {
    display: block;
    .as-oil-cpc__row-title {
      font-size: calc(#{$default_text_size} * 1.2);
    }
  }

  .as-oil-cpc__right {
    width: 100%;

    @media screen and (min-width: 601px) {
      //padding-left: 25px;
      padding-right: 24px;
    }
  }

  .as-oil__btn-optin span {
    font-size: calc(#{$default_text_size} * 1.1);
  }

  .as-oil__btn-optin,
  .as-oil__btn-blue {
    background-color: $button-color_bg;
    transition: all ease 0.5s;
    color: $button-color_font;

    &:hover {
      background-color: $button-color_hover;
    }
  }

  .as-oil__btn-cpc.as-js-advanced-settings {
    min-width: 160px;
    min-height: 42px;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    font-weight: 700;
    border-bottom: none;
    background-color: $button-settings-color_bg;
    transition: all ease 0.5s;
    border-radius: 3px;
    color: $button-settings-color_font;

    &:hover {
      background-color: darken($button-settings-color_bg, 10%);
    }
  }

  .as-oil-cpc__switch {
    input:checked ~ .as-oil-cpc__slider {
      background-color: $slider-color_bg;

      &:before {
        background-color: $slider-color_circle;
      }
    }
  }

  // Colored Fonts:
  .as-oil-cpc__status,
  a {
    color: $accent-color;
  }

  .as-oil-cpc__purpose {
    border-color: $color_purpose;
  }

  .as-oil-cpc__purpose-text {
    font-size: calc(#{$default_text_size} * 0.9);
    color: $color_purpose;
  }
  .as-oil-cpc__purpose-header {
    font-size: $default_text_size;
  }
  .as-oil__heading,
  .as-oil__intro-txt {
    padding: 0 24px;
    margin-left: 0;
    font-size: calc(#{$default_text_size} * 0.9375);
    max-width: 100%;
    min-width: auto;

    @media screen and (min-width: 992px) {
      max-width: 60%;
      max-width: calc(100% - 310px);
    }
    // @media screen and (min-width: 1024px){
    //     max-width: 65%;
    // }
  }
  .as-oil-l-row.as-oil-l-buttons {
    width: 100%;
    max-width: 100%;

    @media screen and (min-width: 992px) {
      max-width: 300px;
      position: relative;
      top: -66px;
    }

    .as-oil-l-item {
      button {
        width: 100%;
        font-size: calc(#{$default_text_size} * 1.1);
      }
    }
  }

  // linke Spalte raus
  .as-oil-cpc__left {
    display: none;
  }

  // Sichern und schließen breiter
  .as-oil-cpc__right .as-oil__btn-optin.as-js-optin {
    @media screen and (min-width: 601px) {
      min-width: 300px;
    }
  }

  // alle aktivieren Button

  .as-js-btn-activate-all.as-oil__btn-blue {
    background-color: $button-activateAll-color_bg;
    color: $button-activateAll-color_font;

    &:hover {
      background-color: darken($button-settings-color_bg, 10%);
    }
  }

  // Aktivieren Buttons nach links
  .as-oil-cpc__row-btn-all {
    //text-align: left;

    @media screen and (min-width: 601px) {
      .as-js-btn-activate-all.as-oil__btn-blue {
        margin-left: 15px;
      }

      .as-js-btn-activate-all.as-oil__btn-blue,
      .as-js-btn-deactivate-all.as-oil__btn-grey {
        display: inline-block;
        min-width: 200px;
        text-align: center;
        font-size: calc(#{$default_text_size} * 1.1);
        padding: 0.6rem 1rem 0rem 1rem;
      }
    }
  }

  // Headlinegrößen mobil korrigieren
  .as-oil__heading {
    font-size: calc(#{$default_text_size} * 1.45);
    @media (max-width: 600px) {
    }
  }

  .as-oil-content-overlay {
    background-color: $background-color !important;
    .text_einstellungen {
      display: none;
    }
  }

  .as-oil-content-overlay[data-qa="oil-cpc-overlay"] {
    .text_ubersicht {
      display: none;
    }

    .text_einstellungen {
      display: block;
    }
  }
}

.container-fluid .karte {
  .map-replacement {
    width: 100%;
  }
}
