@use "../mixins/mediaQueries" as *;

.card {
  max-width: 300px;
  overflow: hidden;
  background: white;
  border-radius: 3px;
  position: relative;
  margin: 0 auto;

  &__link {
    text-decoration: none;
    color: black;
    display: inline-block;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 1em;
    }

    &--5,
    &--6,
    &--7,
    &--8,
    &--9,
    &--10 {
      display: none;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    z-index: 1;
  }

  &__text {
    margin-bottom: 1em;
    overflow: hidden;
    height: 200px;
  }

  &__content {
    padding: 1em;
  }

  &__logo {
    height: 48px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    padding-bottom: 16px;
    bottom: 0;
    left: 16px;
    right: 0;
    height: 250px;
    background-image: linear-gradient(to bottom, transparent 30%, white);
  }
}

@include for-tablet-landscape-up {
  .card {
    &__link {
      display: inline-block;

      &--5,
      &--6,
      &--7,
      &--8 {
        display: inline-block;
      }

      &--1 {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        grid-row: 1 / 3;
      }

      &--2 {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 3;
        -ms-grid-row-span: 3;
        grid-row: 3 / 6;
      }

      &--3 {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 6;
        -ms-grid-row-span: 3;
        grid-row: 6 / 9;
      }

      &--4 {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 9;
        -ms-grid-row-span: 3;
        grid-row: 9 / 12;
      }

      &--5 {
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
        grid-row: 1 / 4;
      }

      &--6 {
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 4;
        -ms-grid-row-span: 3;
        grid-row: 4 / 7;
      }

      &--7 {
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 7;
        -ms-grid-row-span: 3;
        grid-row: 7 / 10;
      }

      &--8 {
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 10;
        -ms-grid-row-span: 2;
        grid-row: 10 / 12;
      }
    }
  }
}

@include for-desktop-up {
  .card {
    height: 100%;

    &__image {
      max-height: 200px;
      object-fit: cover;
    }

    &__text {
      height: auto;
    }

    &__link {
      display: inline-block;
      height: 100%;
      padding: 0.5em;

      &--9,
      &--10 {
        display: inline-block;
      }

      &--1 {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        grid-row: 1 / 3;
      }

      &--2 {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 3;
        -ms-grid-row-span: 3;
        grid-row: 3 / 6;
      }

      &--3 {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 6;
        -ms-grid-row-span: 2;
        grid-row: 6 / 8;
      }

      &--4 {
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
        grid-row: 1 / 4;
      }

      &--5 {
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 4;
        -ms-grid-row-span: 3;
        grid-row: 4 / 7;
      }

      &--6 {
        -ms-grid-column: 3;
        grid-column: 3;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        grid-row: 1 / 3;
      }

      &--7 {
        -ms-grid-column: 3;
        grid-column: 3;
        -ms-grid-row: 3;
        -ms-grid-row-span: 3;
        grid-row: 3 / 6;
      }

      &--8 {
        -ms-grid-column: 3;
        grid-column: 3;
        -ms-grid-row: 6;
        -ms-grid-row-span: 2;
        grid-row: 6 / 8;
      }

      &--9 {
        -ms-grid-column: 4;
        grid-column: 4;
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
        grid-row: 1 / 4;
      }

      &--10 {
        -ms-grid-column: 4;
        grid-column: 4;
        -ms-grid-row: 4;
        -ms-grid-row-span: 3;
        grid-row: 4 / 7;
      }
    }
  }
}
