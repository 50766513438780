@use "../mixins/mediaQueries" as *;
@use "../base/colors" as c;

.button {
  display: inline-block;
  padding: 1em;
  background: c.$dark-grey;
  border: none;
  color: white;
  cursor: pointer;

  &--about {
    display: inline-block;
    margin: 1em auto;
    padding: 1em 2em;
  }

  &--giveaway {
    font-size: 1.125rem;
    background: c.$yellow;
    color: black;
    position: fixed;
    z-index: 1;
    right: 1em;
    bottom: 1em;
    padding: 1em 2em;
    box-shadow: 0 0 10px rgba(black, 0.1);
    border-radius: 100px;
    transition: transform 300ms;

    &:hover {
      transform: scale(1.03);
    }
  }
}

@include for-tablet-landscape-up {
  .button {
    &--giveaway {
      font-size: 1.25rem;
      right: 2em;
      bottom: 2em;
    }
  }
}

@include for-desktop-up {
  .button {
    &--giveaway {
      font-size: 1.375rem;
      right: 3em;
      bottom: 3em;
    }
  }
}

@include for-big-desktop-up {
  .button {
    &--giveaway {
      font-size: 1.5rem;
      right: 4em;
      bottom: 4em;
    }
  }
}
