@use "../mixins/mediaQueries" as *;

.banner {
  position: relative;

  &__image {
    display: block;
    height: 216px;
    width: 100%;
    object-fit: cover;
    object-position: left;
    object-position: center;
  }

  &__video {
    display: block;
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    object-position: center;
  }

  &__text {
    position: absolute;
    top: 20px;
    left: 50%;
    width: 300px;
    transform: translate(-50%);
  }

  &__triangle-container {
    position: absolute;
    bottom: 1em;
    left: 2em;
    display: flex;
    align-items: center;
    pointer-events: none;
  }

  &__triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top: 10px solid white;
    margin-right: 0.5em;
  }
}

@include for-tablet-landscape-up {
  .banner {
    position: relative;
    &__image {
      height: 600px;
      width: 100%;
    }

    &__text {
      top: 100px;
      left: 400px;
      width: 600px;
    }
    &__triangle-container {
      bottom: 2em;
      left: 4em;
    }
  }
}
