@use "../mixins/mediaQueries" as *;
@use "../base/colors" as c;

.about {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__carousel {
    position: relative;
    height: 400px;
    order: 2;
    transition: transform 1.25s;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    background: c.$yellow;
    padding: 2em 1em;
    z-index: 1;
  }

  &__title {
    margin-bottom: 0.5em;
  }

  &__text:not(:last-of-type) {
    margin-bottom: 1em;
  }

  &__button-container {
    text-align: center;
  }
}

@include for-tablet-landscape-up {
  .about {
    flex-direction: row;
    // height: 500px;
    overflow: hidden;

    &__carousel {
      height: auto;
      order: -1;
      width: 50%;
    }

    &__content {
      padding: 2em 1.5em;
      width: 50%;
    }
  }
}

@include for-desktop-up {
  .about {
    &__content {
      padding: 3em 2.5em;
    }
  }
}
