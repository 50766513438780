@use "../mixins/mediaQueries" as *;
@use "../base/colors" as c;

.giveaway {
  background: c.$yellow;
  padding: 2em 1em;

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  &__title {
    margin-bottom: 1em;
    max-width: 1000px;
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  &__video {
    margin: 2em auto;
    width: 100%;
    max-width: 600px;
  }

  &__text {
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }

  &__list {
    max-width: 1000px;
    margin: 2em auto;
    padding: 0 2em;
  }
}

@include for-tablet-portrait-up {
  .giveaway {
    padding: 2em;
  }
}

@include for-tablet-landscape-up {
  .giveaway {
    padding-top: 3em;

    &__content::after {
      content: "";
      display: table;
      clear: both;
    }

    &__text {
      margin-bottom: 1em;
    }

    &__video {
      float: right;
      max-width: 50%;
      margin: 0 2em;
    }

    &__legal {
      max-width: 1000px;
      margin: 0 auto;
    }
  }
}
