*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  hyphens: auto;
}

body {
  background: url("../../images/bg_body.jpg");
}

img,
video {
  display: block;
}
