*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  hyphens: auto;
}

body {
  background: url("../../images/bg_body.jpg");
}

img,
video {
  display: block;
}

@font-face {
  font-family: "KLBulletinSans-Regular";
  src: url("/fonts/KLBulletinSans-Regular.eot"), url("/fonts/KLBulletinSans-Regular.woff"), url("/fonts/KLBulletinSans-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "KLBulletinSans-Bold";
  src: url("/fonts/KLBulletinSans-Bold.eot"), url("/fonts/KLBulletinSans-Bold.woff"), url("/fonts/KLBulletinSans-Bold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
}
body {
  font-family: "KLBulletinSans-Regular", sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  hyphens: none;
}

.header__language-picker,
.header__link,
.banner__triangle-container {
  color: white;
  text-decoration: none;
}

.nav__link {
  text-decoration: none;
  color: #333;
  font-size: 1.5rem;
}

.about__title,
.timeline__title,
.stories__title,
.giveaway__title,
.moments__title {
  font-family: "KLBulletinSans-Bold", sans-serif;
  font-size: 1.75rem;
  font-weight: 400;
}

.about__text,
.timeline__text,
.stories__text,
.giveaway__text,
.giveaway__list-item {
  line-height: 1.5;
}

.timeline__quote,
.timeline__quote-author {
  font-weight: 400;
  font-style: italic;
  line-height: 1.4;
}

.timeline__title,
.giveaway__title,
.moments__title,
.moments__subtitle {
  text-align: center;
}

.timeline__quote {
  font-size: 1.25rem;
}

.timeline__quote-author {
  font-size: 0.75rem;
  text-align: right;
}

.form__button,
.form__input,
.button {
  font-size: 1rem;
  text-decoration: none;
}

.button--about {
  font-family: Arial;
}

.form__button,
.button {
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (min-width: 900px) {
  .about__title,
.timeline__title,
.stories__title,
.giveaway__title,
.moments__title {
    font-size: 2.5rem;
  }

  .nav__link {
    font-size: 1.25rem;
  }

  .timeline__text {
    font-size: 1.25rem;
  }

  .about__text,
.stories__text,
.giveaway__text,
.giveaway__list-item {
    font-size: 1.1rem;
  }

  .timeline__quote {
    font-size: 2.25rem;
  }

  .timeline__quote-author {
    font-size: 1.25rem;
  }

  .timeline__title {
    text-align: left;
  }

  .moments__subtitle {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1200px) {
  .about__text,
.stories__text,
.giveaway__text,
.giveaway__list-item {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1800px) {
  .about__title,
.timeline__title,
.stories__title,
.giveaway__title,
.moments__title {
    font-size: 3rem;
  }

  .about__title--es {
    font-size: 2.8rem;
  }

  .about__text,
.stories__text,
.giveaway__text,
.giveaway__list-item {
    font-size: 1.5rem;
  }

  .timeline__quote {
    font-size: 2.5rem;
  }
}
.about {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.about__carousel {
  position: relative;
  height: 400px;
  order: 2;
  transition: transform 1.25s;
}
.about__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about__content {
  background: #ffed00;
  padding: 2em 1em;
  z-index: 1;
}
.about__title {
  margin-bottom: 0.5em;
}
.about__text:not(:last-of-type) {
  margin-bottom: 1em;
}
.about__button-container {
  text-align: center;
}

@media only screen and (min-width: 900px) {
  .about {
    flex-direction: row;
    overflow: hidden;
  }
  .about__carousel {
    height: auto;
    order: -1;
    width: 50%;
  }
  .about__content {
    padding: 2em 1.5em;
    width: 50%;
  }
}
@media only screen and (min-width: 1200px) {
  .about__content {
    padding: 3em 2.5em;
  }
}
.banner {
  position: relative;
}
.banner__image {
  display: block;
  height: 216px;
  width: 100%;
  object-fit: cover;
  object-position: left;
  object-position: center;
}
.banner__video {
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
}
.banner__text {
  position: absolute;
  top: 20px;
  left: 50%;
  width: 300px;
  transform: translate(-50%);
}
.banner__triangle-container {
  position: absolute;
  bottom: 1em;
  left: 2em;
  display: flex;
  align-items: center;
  pointer-events: none;
}
.banner__triangle {
  display: inline-block;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top: 10px solid white;
  margin-right: 0.5em;
}

@media only screen and (min-width: 900px) {
  .banner {
    position: relative;
  }
  .banner__image {
    height: 600px;
    width: 100%;
  }
  .banner__text {
    top: 100px;
    left: 400px;
    width: 600px;
  }
  .banner__triangle-container {
    bottom: 2em;
    left: 4em;
  }
}
.button {
  display: inline-block;
  padding: 1em;
  background: #333;
  border: none;
  color: white;
  cursor: pointer;
}
.button--about {
  display: inline-block;
  margin: 1em auto;
  padding: 1em 2em;
}
.button--giveaway {
  font-size: 1.125rem;
  background: #ffed00;
  color: black;
  position: fixed;
  z-index: 1;
  right: 1em;
  bottom: 1em;
  padding: 1em 2em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transition: transform 300ms;
}
.button--giveaway:hover {
  transform: scale(1.03);
}

@media only screen and (min-width: 900px) {
  .button--giveaway {
    font-size: 1.25rem;
    right: 2em;
    bottom: 2em;
  }
}
@media only screen and (min-width: 1200px) {
  .button--giveaway {
    font-size: 1.375rem;
    right: 3em;
    bottom: 3em;
  }
}
@media only screen and (min-width: 1800px) {
  .button--giveaway {
    font-size: 1.5rem;
    right: 4em;
    bottom: 4em;
  }
}
.card {
  max-width: 300px;
  overflow: hidden;
  background: white;
  border-radius: 3px;
  position: relative;
  margin: 0 auto;
}
.card__link {
  text-decoration: none;
  color: black;
  display: inline-block;
  width: 100%;
}
.card__link:not(:last-of-type) {
  margin-bottom: 1em;
}
.card__link--5, .card__link--6, .card__link--7, .card__link--8, .card__link--9, .card__link--10 {
  display: none;
}
.card__image {
  position: relative;
  width: 100%;
  z-index: 1;
}
.card__text {
  margin-bottom: 1em;
  overflow: hidden;
  height: 200px;
}
.card__content {
  padding: 1em;
}
.card__logo {
  height: 48px;
}
.card__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  padding-bottom: 16px;
  bottom: 0;
  left: 16px;
  right: 0;
  height: 250px;
  background-image: linear-gradient(to bottom, transparent 30%, white);
}

@media only screen and (min-width: 900px) {
  .card__link {
    display: inline-block;
  }
  .card__link--5, .card__link--6, .card__link--7, .card__link--8 {
    display: inline-block;
  }
  .card__link--1 {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1/3;
  }
  .card__link--2 {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 3;
    grid-row: 3/6;
  }
  .card__link--3 {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 6;
    -ms-grid-row-span: 3;
    grid-row: 6/9;
  }
  .card__link--4 {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 9;
    -ms-grid-row-span: 3;
    grid-row: 9/12;
  }
  .card__link--5 {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1/4;
  }
  .card__link--6 {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 4;
    -ms-grid-row-span: 3;
    grid-row: 4/7;
  }
  .card__link--7 {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 7;
    -ms-grid-row-span: 3;
    grid-row: 7/10;
  }
  .card__link--8 {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 10;
    -ms-grid-row-span: 2;
    grid-row: 10/12;
  }
}
@media only screen and (min-width: 1200px) {
  .card {
    height: 100%;
  }
  .card__image {
    max-height: 200px;
    object-fit: cover;
  }
  .card__text {
    height: auto;
  }
  .card__link {
    display: inline-block;
    height: 100%;
    padding: 0.5em;
  }
  .card__link--9, .card__link--10 {
    display: inline-block;
  }
  .card__link--1 {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1/3;
  }
  .card__link--2 {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 3;
    grid-row: 3/6;
  }
  .card__link--3 {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 6;
    -ms-grid-row-span: 2;
    grid-row: 6/8;
  }
  .card__link--4 {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1/4;
  }
  .card__link--5 {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 4;
    -ms-grid-row-span: 3;
    grid-row: 4/7;
  }
  .card__link--6 {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1/3;
  }
  .card__link--7 {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 3;
    grid-row: 3/6;
  }
  .card__link--8 {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 6;
    -ms-grid-row-span: 2;
    grid-row: 6/8;
  }
  .card__link--9 {
    -ms-grid-column: 4;
    grid-column: 4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1/4;
  }
  .card__link--10 {
    -ms-grid-column: 4;
    grid-column: 4;
    -ms-grid-row: 4;
    -ms-grid-row-span: 3;
    grid-row: 4/7;
  }
}
@media only screen and (min-width: 600px) {
  .container {
    margin: 0 auto;
    max-width: 1300px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  }
  .container--no-shadow {
    box-shadow: none;
  }
}
.footer {
  background: #333;
  position: relative;
  padding: 2em 2em 3em;
}
.footer strong {
  font-weight: 700;
  font-family: "KLBulletinSans-Bold", arial, sans-serif;
  font-weight: 400;
}
.footer__list {
  color: white;
  list-style: none;
  margin-bottom: 2em;
}
.footer__list--address {
  display: flex;
  flex-direction: column;
}
.footer__list--social {
  margin: 0;
}
.footer__list-item, .footer__link {
  color: white;
}
.footer__list-item {
  margin-bottom: 0.25em;
}
.footer__link {
  text-decoration: none;
}
.footer__link:hover, .footer__link:focus {
  text-decoration: underline;
}
.footer__social {
  position: absolute;
  right: 2rem;
  bottom: 2.5em;
  font-size: 2rem;
}
.footer__social-icon {
  color: white;
  text-decoration: none;
}
.footer__social-icon:hover, .footer__social-icon:focus {
  color: #ffed00;
}

@media only screen and (min-width: 1200px) {
  .footer {
    padding: 2.5em 6em 3em;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .footer__container {
    display: flex;
    align-items: flex-end;
  }
  .footer__list {
    display: flex;
    margin-bottom: 0;
  }
  .footer__list:not(:last-of-type) {
    margin: 0;
  }
  .footer__list--address:not(:last-of-type) {
    margin-right: 4em;
  }
  .footer__list-item:not(:last-of-type) {
    margin-right: 1em;
  }
  .footer__social {
    position: absolute;
    font-size: 2rem;
    right: 2.5em;
    top: 1em;
    bottom: auto;
  }
}
.form {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  margin-bottom: 5em;
}
.form label {
  margin-left: 0.5em;
  margin-bottom: 0.5em;
}
.form__input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form__input-container--newsletter {
  flex-direction: row;
  align-items: baseline;
}
.form__input {
  padding: 1em;
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5em;
}
.form__button {
  padding: 1em;
  background: #333;
  border: none;
  color: white;
  cursor: pointer;
}

@media only screen and (min-width: 900px) {
  .form {
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 5em;
  }
  .form__input-container {
    margin-right: 1em;
    width: auto;
  }
  .form__input-container a {
    color: black;
  }
  .form__input-container--email {
    grid-row: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .form__input-container--name {
    grid-row: 1;
    grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
  .form__input-container--address {
    grid-row: 2;
    grid-column: 1/-1;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
  .form__input-container--city {
    grid-row: 3;
    grid-column: 2;
    -ms-grid-row: 3;
    -ms-grid-column: 2;
  }
  .form__input-container--zip {
    grid-row: 3;
    grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  .form__input-container--tel {
    grid-row: 4;
    grid-column: 1/-1;
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
  .form__input-container--newsletter {
    grid-row: 5;
    grid-column: 1/-1;
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
  .form__button {
    grid-row: 6;
    -ms-grid-row: 6;
    grid-column: 1/-1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    margin-right: 16px;
    margin-left: auto;
  }
}
.giveaway {
  background: #ffed00;
  padding: 2em 1em;
}
.giveaway::after {
  content: "";
  display: table;
  clear: both;
}
.giveaway__title {
  margin-bottom: 1em;
  max-width: 1000px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
.giveaway__video {
  margin: 2em auto;
  width: 100%;
  max-width: 600px;
}
.giveaway__text {
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 1.5em;
}
.giveaway__list {
  max-width: 1000px;
  margin: 2em auto;
  padding: 0 2em;
}

@media only screen and (min-width: 600px) {
  .giveaway {
    padding: 2em;
  }
}
@media only screen and (min-width: 900px) {
  .giveaway {
    padding-top: 3em;
  }
  .giveaway__content::after {
    content: "";
    display: table;
    clear: both;
  }
  .giveaway__text {
    margin-bottom: 1em;
  }
  .giveaway__video {
    float: right;
    max-width: 50%;
    margin: 0 2em;
  }
  .giveaway__legal {
    max-width: 1000px;
    margin: 0 auto;
  }
}
.header {
  background: #ffed00;
}
.header__language-picker {
  text-align: right;
  padding: 0.5em 1em;
  background: #333;
}

@media only screen and (min-width: 900px) {
  .header__language-picker {
    display: block;
    min-height: 2em;
  }
}
.imprint {
  background: white;
  padding-top: 2em;
}
.imprint h1,
.imprint h2,
.imprint h3,
.imprint p {
  margin: 1em 1em 0.5em;
}
.imprint p,
.imprint li {
  line-height: 1.4;
}
.imprint p strong {
  display: inline-block;
  margin: 1em 0 0.5em;
}
.imprint a {
  color: black;
}
.imprint ul {
  margin-left: 1em;
}
.imprint li {
  margin-left: 1em;
}

.moments {
  background: #ffed00;
  padding: 2em;
}
.moments__title {
  margin-bottom: 0.25em;
}
.moments__subtitle {
  margin-bottom: 2em;
}

@media only screen and (min-width: 900px) {
  .moments {
    padding: 3em 2em;
  }
  .moments__container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
}
@media only screen and (min-width: 1200px) {
  .moments__container {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: 180px 180px 180px 180px 180px 180px 180px;
    grid-template-rows: repeat(7, 180px);
  }
}
.as-oil.light {
  color: #000;
}
.as-oil.light .as-oil-cpc__content {
  display: block;
}
.as-oil.light .as-oil-cpc__content .as-oil-cpc__row-title {
  font-size: calc(18px * 1.2);
}
.as-oil.light .as-oil-cpc__right {
  width: 100%;
}
@media screen and (min-width: 601px) {
  .as-oil.light .as-oil-cpc__right {
    padding-right: 24px;
  }
}
.as-oil.light .as-oil__btn-optin span {
  font-size: calc(18px * 1.1);
}
.as-oil.light .as-oil__btn-optin,
.as-oil.light .as-oil__btn-blue {
  background-color: #333333;
  transition: all ease 0.5s;
  color: #f1f1f1;
}
.as-oil.light .as-oil__btn-optin:hover,
.as-oil.light .as-oil__btn-blue:hover {
  background-color: #262626;
}
.as-oil.light .as-oil__btn-cpc.as-js-advanced-settings {
  min-width: 160px;
  min-height: 42px;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  font-weight: 700;
  border-bottom: none;
  background-color: #f1f1f1ee;
  transition: all ease 0.5s;
  border-radius: 3px;
  color: #919191;
}
.as-oil.light .as-oil__btn-cpc.as-js-advanced-settings:hover {
  background-color: rgba(216, 216, 216, 0.9333333333);
}
.as-oil.light .as-oil-cpc__switch input:checked ~ .as-oil-cpc__slider {
  background-color: #333333;
}
.as-oil.light .as-oil-cpc__switch input:checked ~ .as-oil-cpc__slider:before {
  background-color: #fff;
}
.as-oil.light .as-oil-cpc__status,
.as-oil.light a {
  color: #333333;
}
.as-oil.light .as-oil-cpc__purpose {
  border-color: #4d4d4d;
}
.as-oil.light .as-oil-cpc__purpose-text {
  font-size: calc(18px * 0.9);
  color: #4d4d4d;
}
.as-oil.light .as-oil-cpc__purpose-header {
  font-size: 18px;
}
.as-oil.light .as-oil__heading,
.as-oil.light .as-oil__intro-txt {
  padding: 0 24px;
  margin-left: 0;
  font-size: calc(18px * 0.9375);
  max-width: 100%;
  min-width: auto;
}
@media screen and (min-width: 992px) {
  .as-oil.light .as-oil__heading,
.as-oil.light .as-oil__intro-txt {
    max-width: 60%;
    max-width: calc(100% - 310px);
  }
}
.as-oil.light .as-oil-l-row.as-oil-l-buttons {
  width: 100%;
  max-width: 100%;
}
@media screen and (min-width: 992px) {
  .as-oil.light .as-oil-l-row.as-oil-l-buttons {
    max-width: 300px;
    position: relative;
    top: -66px;
  }
}
.as-oil.light .as-oil-l-row.as-oil-l-buttons .as-oil-l-item button {
  width: 100%;
  font-size: calc(18px * 1.1);
}
.as-oil.light .as-oil-cpc__left {
  display: none;
}
@media screen and (min-width: 601px) {
  .as-oil.light .as-oil-cpc__right .as-oil__btn-optin.as-js-optin {
    min-width: 300px;
  }
}
.as-oil.light .as-js-btn-activate-all.as-oil__btn-blue {
  background-color: #f1f1f1;
  color: #333333;
}
.as-oil.light .as-js-btn-activate-all.as-oil__btn-blue:hover {
  background-color: rgba(216, 216, 216, 0.9333333333);
}
@media screen and (min-width: 601px) {
  .as-oil.light .as-oil-cpc__row-btn-all .as-js-btn-activate-all.as-oil__btn-blue {
    margin-left: 15px;
  }
  .as-oil.light .as-oil-cpc__row-btn-all .as-js-btn-activate-all.as-oil__btn-blue,
.as-oil.light .as-oil-cpc__row-btn-all .as-js-btn-deactivate-all.as-oil__btn-grey {
    display: inline-block;
    min-width: 200px;
    text-align: center;
    font-size: calc(18px * 1.1);
    padding: 0.6rem 1rem 0rem 1rem;
  }
}
.as-oil.light .as-oil__heading {
  font-size: calc(18px * 1.45);
}
.as-oil.light .as-oil-content-overlay {
  background-color: #ffee00 !important;
}
.as-oil.light .as-oil-content-overlay .text_einstellungen {
  display: none;
}
.as-oil.light .as-oil-content-overlay[data-qa=oil-cpc-overlay] .text_ubersicht {
  display: none;
}
.as-oil.light .as-oil-content-overlay[data-qa=oil-cpc-overlay] .text_einstellungen {
  display: block;
}

.container-fluid .karte .map-replacement {
  width: 100%;
}

.nav {
  display: flex;
  align-items: center;
  padding: 0.25em 1em;
  position: relative;
  z-index: 10;
  min-height: 4em;
}
.nav__logo {
  position: absolute;
  height: 64px;
  top: -1rem;
}
.nav__button {
  border: none;
  background: transparent;
  font-size: 1.5rem;
}
.nav__list {
  text-align: center;
  position: absolute;
  top: 75px;
  width: 90%;
  max-width: 700px;
  left: 50%;
  padding: 2em;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) inset;
  background: #f2e200;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 300ms ease-in, opacity 200ms ease-in;
  list-style: none;
  border-radius: 3px;
}
.nav__list-item {
  margin-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.nav__list-item:first-child {
  margin-top: 0;
}
.nav__list-item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.nav__list-item--language {
  position: absolute;
  margin-bottom: 0;
  padding-bottom: 0;
  top: 0.5em;
  right: 0.5em;
  border-bottom: none;
  font-size: 1.25rem;
}
.nav__list-item--language a {
  font-size: 1.25rem;
}
.nav__link {
  display: inline-block;
}
.nav__link:not(:last-of-type) {
  padding-bottom: 1em;
  margin-bottom: 1em;
}
.nav__button {
  cursor: pointer;
}
.nav__languages {
  display: flex;
  gap: 1em;
}

@media only screen and (min-width: 900px) {
  .nav {
    justify-content: flex-end;
    padding-top: 0;
    padding-bottom: 0;
  }
  .nav__list {
    position: static;
    transform: translate(0);
    opacity: 1;
    display: flex;
    flex-direction: row;
    width: auto;
    max-width: auto;
    padding: 0;
    background: transparent;
    box-shadow: none;
    margin-left: 8em;
  }
  .nav__list-item {
    margin-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .nav__list-item:first-child {
    margin-top: 0;
  }
  .nav__list-item:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
  .nav__item:not(:last-of-type) {
    margin: 0;
    border-bottom: none;
  }
  .nav__link {
    padding: 1.5em;
    box-shadow: inset 3px 1px 9px -9px #000;
  }
  .nav__link:hover, .nav__link:focus {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .nav__logo {
    top: -25px;
    left: 40px;
    height: 80px;
  }
  .nav__button {
    display: none;
  }
}
.stories {
  position: relative;
  background: white;
}
.stories__carousel {
  position: relative;
  transition: transform 1.25s ease-in;
}
.stories__item {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.stories__item-content {
  background: white;
  padding: 2em 1em;
}
.stories__title {
  margin-bottom: 0.5em;
}
.stories__text:not(:last-of-type) {
  margin-bottom: 1em;
}
.stories__video-container {
  padding: 1em;
}
.stories__video {
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .stories__item {
    display: flex;
    align-items: stretch;
  }
  .stories__image {
    flex: 1;
    width: 50%;
    height: 100%;
    z-index: initial;
  }
  .stories__item-content {
    padding: 3em 2.5em;
    width: 50%;
  }
  .stories__video-container {
    width: 50%;
    display: flex;
    align-items: center;
    padding-right: 1em;
  }
  .stories__video {
    width: 100%;
  }
}
.timeline {
  background: white;
  padding: 4em 0;
}
.timeline__title {
  margin: 1em 0 0.5em;
  text-align: left;
}
.timeline__image {
  display: block;
  margin: 1em 0;
  width: 100%;
  max-width: 100%;
  max-height: 450px;
  object-fit: cover;
}
.timeline__video {
  display: block;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}
.timeline__item {
  margin: 2em 0;
}
.timeline__item:first-child {
  margin: 0 0 2em;
}
.timeline__item:last-child {
  margin: 2em 0 0;
}
.timeline__item-content {
  padding: 0 1em;
}
.timeline__item-content a {
  color: black;
}

@media only screen and (min-width: 600px) {
  .timeline__title {
    margin: 0 0 0.25em 0;
  }
  .timeline__item {
    display: flex;
    align-items: center;
    padding: 0 2em;
    gap: 2em;
    margin: 4em 0;
  }
  .timeline__item:first-child {
    margin: 0 0 4em;
  }
  .timeline__item:last-child {
    margin: 4em 0 0;
  }
  .timeline__item:nth-child(even) {
    flex-direction: row-reverse;
  }
  .timeline__item > * {
    width: 100%;
    flex-grow: 1;
  }
  .timeline__item-content {
    padding: 0;
  }
  .timeline__image {
    width: 500px;
    max-width: 40%;
    object-fit: cover;
    box-shadow: 8px 8px #ffed00, -8px -8px black;
    margin: 0;
  }
}
@media only screen and (min-width: 900px) {
  .timeline__image {
    max-width: 50%;
  }
  .timeline__item {
    margin: 4em 0;
  }
}