@use "../mixins/mediaQueries" as *;
@use "colors" as c;

@font-face {
  font-family: "KLBulletinSans-Regular";
  src: url("/fonts/KLBulletinSans-Regular.eot"),
    url("/fonts/KLBulletinSans-Regular.woff"),
    url("/fonts/KLBulletinSans-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "KLBulletinSans-Bold";
  src: url("/fonts/KLBulletinSans-Bold.eot"),
    url("/fonts/KLBulletinSans-Bold.woff"),
    url("/fonts/KLBulletinSans-Bold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "KLBulletinSans-Regular", sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  hyphens: none;
}

.header__language-picker,
.header__link,
.banner__triangle-container {
  color: white;
  text-decoration: none;
}

.nav__link {
  text-decoration: none;
  color: c.$dark-grey;
  font-size: 1.5rem;
}

.about__title,
.timeline__title,
.stories__title,
.giveaway__title,
.moments__title {
  font-family: "KLBulletinSans-Bold", sans-serif;
  font-size: 1.75rem;
  font-weight: 400;
}

.about__text,
.timeline__text,
.stories__text,
.giveaway__text,
.giveaway__list-item {
  line-height: 1.5;
}

.timeline__quote,
.timeline__quote-author {
  font-weight: 400;
  font-style: italic;
  line-height: 1.4;
}

.timeline__title,
.giveaway__title,
.moments__title,
.moments__subtitle {
  text-align: center;
}

.timeline__quote {
  font-size: 1.25rem;
}

.timeline__quote-author {
  font-size: 0.75rem;
  text-align: right;
}

.form__button,
.form__input,
.button {
  font-size: 1rem;
  text-decoration: none;
}

.button--about {
  font-family: Arial;
}

.form__button,
.button {
  font-weight: 700;
  text-transform: uppercase;
}

@include for-tablet-landscape-up {
  .about__title,
  .timeline__title,
  .stories__title,
  .giveaway__title,
  .moments__title {
    font-size: 2.5rem;
  }

  .nav__link {
    font-size: 1.25rem;
  }

  .timeline__text {
    font-size: 1.25rem;
  }

  .about__text,
  .stories__text,
  .giveaway__text,
  .giveaway__list-item {
    font-size: 1.1rem;
  }

  .timeline__quote {
    font-size: 2.25rem;
  }

  .timeline__quote-author {
    font-size: 1.25rem;
  }

  .timeline__title {
    text-align: left;
  }

  .moments__subtitle {
    font-size: 2rem;
  }
}

@include for-desktop-up {
  .about__text,
  .stories__text,
  .giveaway__text,
  .giveaway__list-item {
    font-size: 1.25rem;
  }
}

@include for-big-desktop-up {
  .about__title,
  .timeline__title,
  .stories__title,
  .giveaway__title,
  .moments__title {
    font-size: 3rem;
  }

  .about__title--es {
    font-size: 2.8rem;
  }

  .about__text,
  .stories__text,
  .giveaway__text,
  .giveaway__list-item {
    font-size: 1.5rem;
  }

  .timeline__quote {
    font-size: 2.5rem;
  }
}
