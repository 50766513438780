@use "../mixins/mediaQueries" as *;

.stories {
  position: relative;
  background: white;

  &__carousel {
    position: relative;
    transition: transform 1.25s ease-in;
  }

  &__item {
    //position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__item-content {
    background: white;
    padding: 2em 1em;
  }

  &__title {
    margin-bottom: 0.5em;
  }

  &__text {
    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }

  &__video-container {
    padding: 1em;
  }

  &__video {
    width: 100%;
  }
}

@include for-desktop-up {
  .stories {
    &__item {
      display: flex;
      align-items: stretch;
    }

    &__image {
      flex: 1;
      width: 50%;
      height: 100%;
      z-index: initial;
    }

    &__item-content {
      //padding: 1.5em 2em 2em 4em;
      padding: 3em 2.5em;
      width: 50%;
    }

    &__video-container {
      width: 50%;
      display: flex;
      align-items: center;
      padding-right: 1em;
    }

    &__video {
      width: 100%;
    }
  }
}
