@use "../mixins/mediaQueries" as *;
@use "../base/colors" as c;

.form {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  margin-bottom: 5em;

  label {
    margin-left: 0.5em;
    margin-bottom: 0.5em;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--newsletter {
      flex-direction: row;
      align-items: baseline;
    }
  }

  &__input {
    padding: 1em;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5em;
  }

  &__button {
    padding: 1em;
    background: c.$dark-grey;
    border: none;
    color: white;
    cursor: pointer;
  }
}

@include for-tablet-landscape-up {
  .form {
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 5em;

    &__input-container {
      margin-right: 1em;
      width: auto;

      a {
        color: black;
      }

      &--email {
        grid-row: 1;
        grid-column: 1;
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }

      &--name {
        grid-row: 1;
        grid-column: 2;
        -ms-grid-row: 1;
        -ms-grid-column: 2;
      }

      &--address {
        grid-row: 2;
        grid-column: 1 / -1;
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
      }

      &--city {
        grid-row: 3;
        grid-column: 2;
        -ms-grid-row: 3;
        -ms-grid-column: 2;
      }

      &--zip {
        grid-row: 3;
        grid-column: 1;
        -ms-grid-row: 3;
        -ms-grid-column: 1;
      }

      &--tel {
        grid-row: 4;
        grid-column: 1 / -1;
        -ms-grid-row: 4;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
      }

      &--newsletter {
        grid-row: 5;
        grid-column: 1 / -1;
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
      }
    }

    &__button {
      grid-row: 6;
      -ms-grid-row: 6;
      grid-column: 1 / -1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      margin-right: 16px;
      margin-left: auto;
    }
  }
}
