@use "../mixins/mediaQueries" as *;
@use "../base/colors" as c;

.timeline {
  background: white;
  padding: 4em 0;

  &__title {
    margin: 1em 0 0.5em;
    text-align: left;
  }

  &__image {
    display: block;
    margin: 1em 0;
    width: 100%;
    max-width: 100%;
    max-height: 450px;
    object-fit: cover;
  }

  &__video {
    display: block;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }

  &__item {
    margin: 2em 0;

    &:first-child {
      margin: 0 0 2em;
    }

    &:last-child {
      margin: 2em 0 0;
    }
  }

  &__item-content {
    padding: 0 1em;

    a {
      color: black;
    }
  }
}

@include for-tablet-portrait-up {
  .timeline {
    &__title {
      margin: 0 0 0.25em 0;
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 0 2em;
      gap: 2em;
      margin: 4em 0;

      &:first-child {
        margin: 0 0 4em;
      }

      &:last-child {
        margin: 4em 0 0;
      }

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      & > * {
        width: 100%;
        flex-grow: 1;
      }
    }

    &__item-content {
      padding: 0;
    }

    &__image {
      width: 500px;
      max-width: 40%;
      object-fit: cover;
      box-shadow: 8px 8px c.$yellow, -8px -8px black;
      margin: 0;
    }
  }
}

@include for-tablet-landscape-up {
  .timeline {
    &__image {
      max-width: 50%;
    }

    &__item {
      margin: 4em 0;
    }
  }
}
